@use '@angular/material' as mat;
@mixin register-2-theme($theme) {

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    register-2 {

        #register {

            #register-intro {
                color: white;
            }

            #register-form-wrapper {
                @if ($is-dark) {
                    background: mat.get-color-from-palette($fuse-navy, 600);
                } @else {
                    background: map-get($background, card);
                }

                #register-form {

                    .separator {
                        color: map-get($foreground, divider);

                        .text {

                            &:before,
                            &:after {
                                border-top-color: map-get($foreground, divider);
                            }
                        }
                    }
                }
            }
        }
    }
}