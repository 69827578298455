@mixin profile-timeline-theme($theme) {

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $accent: map-get($theme, accent);

    profile-timeline {

        #timeline {

            .timeline-content {

                .add-post {

                    textarea {
                        color: map-get($foreground, text);
                        background: map-get($background, card);
                    }

                    footer {
                        border-top-color: map-get($foreground, divider);
                        background: map-get($background, app-bar);
                    }
                }

                .timeline-item {
                    background: map-get($background, card);

                    header {

                        .title {

                            .username {
                                color: map-get($accent, default);
                            }
                        }

                        .time {
                            color: map-get($foreground, secondary-text);
                        }
                    }

                    .content {

                        .article {
                            border-color: map-get($foreground, divider);

                            .media {
                                border-bottom-color: map-get($foreground, divider);
                            }

                            .subtitle {
                                color: map-get($foreground, secondary-text);
                            }
                        }
                    }

                    footer {
                        border-top-color: map-get($foreground, divider);
                        background-color: rgba(0, 0, 0, 0.04);

                        .comment {

                            .message {
                                color: map-get($foreground, text);
                            }

                            .time {
                                color: map-get($foreground, secondary-text);
                            }

                            .actions {

                                .reply-button {
                                    color: map-get($accent, default);
                                }
                            }
                        }

                        .reply {

                            textarea {
                                background-color: map-get($background, card);
                                border-color: map-get($foreground, divider);
                                color: map-get($foreground, text);
                            }
                        }
                    }
                }
            }

            .timeline-sidebar {

                .latest-activity {

                    .content {
                        background-color: map-get($background, card);

                        .activities {

                            .activity {

                                .username {
                                    color: map-get($accent, default);
                                }
                            }
                        }
                    }
                }
            }
        }

        .profile-box {

            .content {
                background-color: map-get($background, card);
            }

            footer {
                border-top-color: map-get($foreground, divider);
                background-color: rgba(0, 0, 0, 0.06);
            }
        }
    }
}