@mixin knowledge-base-theme($theme) {

    $background: map-get($theme, background);

    knowledge-base {

        .content {

            .articles-list {
                background-color: map-get($background, card);
            }
        }
    }
}