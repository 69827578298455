@use '@angular/material' as mat;
@mixin reset-password-2-theme($theme) {

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    reset-password-2 {

        #reset-password {

            #reset-password-intro {
                color: white;
            }

            #reset-password-form-wrapper {
                @if ($is-dark) {
                    background: mat.get-color-from-palette($fuse-navy, 600);
                } @else {
                    background: map-get($background, card);
                }
            }
        }
    }
}