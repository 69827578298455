@mixin file-manager-theme($theme) {

    $background: map-get($theme, background);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    file-list {

        .mat-table {

            .mat-row {

                &.selected {
                    @if ($is-dark) {
                        background: map-get($background, hover);
                    } @else {
                        background: map-get($accent, 50);
                    }
                }
            }
        }
    }
}