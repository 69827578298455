@mixin maintenance-theme($theme) {

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    maintenance {

        #maintenance {

            #maintenance-form-wrapper {

                #maintenance-form {
                    background: map-get($background, card);

                    .subtitle {
                        color: map-get($foreground, secondary-text);
                    }
                }
            }
        }
    }
}