@mixin e-commerce-products-theme($theme) {

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    e-commerce-products {

        .header {

            .search-wrapper {
                background: map-get($background, card);

                .search {

                    .mat-icon {
                        color: map-get($foreground, icon);
                    }

                    input {
                        background: map-get($background, card);
                        color: map-get($foreground, text);
                    }
                }
            }
        }
    }
}
